import React from 'react'
import { Link, PageProps } from 'gatsby'
import { Lightning } from 'phosphor-react'
import { BasicLayout } from '../../layouts/basic'
import { SEOMeta } from '../../components/SEOMeta'
import { FigmaSignupBar } from '../../components/auth/FigmaSignupBar'
import figmaIcon from '../../images/figma-icon.svg'
import figmaLatestDesigns from '../../images/figma-latest-designs.svg'
import figmaCopyPaste from '../../images/figma-copy-paste.png'
import figmaRefresh from '../../images/figma-refresh.svg'

const Figma: React.FC<PageProps> = ({ location }) => (
  <BasicLayout>
    <SEOMeta
      title="Whiteboard for Figma"
      description="Work with Figma frames with your team on a mutiplayer realtime whiteboard, and mix and match with other integrations like GitHub, Jira, and Linear to write tasks and issues"
      path={location.pathname}
    />
    <div className="max-w-[720px] mx-auto px-4">
      <header className="mt-8 px-2 text-center">
        <picture className="block mx-auto w-24 h-24 sm:w-32 sm:h-32">
          <img className="w-full h-full" src={figmaIcon} alt="Figma icon" />
        </picture>
        <h1 className="text-text-primary-light mt-4 text-3xl font-extrabold tracking-tight leading-tight sm:text-5xl">
          Figma
        </h1>
        <p className="text-text-primary-light mt-4 mx-auto max-w-xl text-base sm:mt-6 sm:text-lg">
          Import living copies of your Figma frames that always stay up to date.
          Mix and match with other integrations like GitHub, Jira, and Linear to
          write tasks and issues that visually relate to the latest designs.
        </p>
        <a
          className="group bg-background-one-light rounded-[20px] flex items-center mt-8 mx-auto p-1 w-max cursor-pointer sm:mt-10"
          href={process.env.SIGNUP_URL}
        >
          <div className="to-background-five-light from-background-four-light rounded-[16px] w-[90px] h-[48px] flex items-center justify-center bg-gradient-to-t">
            <Lightning
              className="text-icon-secondary-light group-hover:text-teal-400 w-6 h-6"
              weight="fill"
            />
          </div>
          <div className="text-text-secondary-light w-[90px] h-[48px] flex items-center justify-center text-base font-medium tracking-tight leading-tight">
            Connect
          </div>
        </a>
        <p className="text-text-secondary-light mt-4 text-sm">
          Sign up for Qualdesk and connect Figma in under a minute
        </p>
      </header>
      <figure className="bg-light-blue-100 md:h-[348px] relative flex flex-col-reverse justify-between mb-4 mt-12 rounded-xl overflow-hidden sm:mt-14">
        <div className="ring-opacity-[16%] ring-light-blue-800 absolute inset-0 rounded-xl ring-2 ring-inset" />
        <img
          className="max-w-[440px] max-h-[348px] self-end mt-8 w-full"
          src={figmaLatestDesigns}
          alt="View your latest designs while writing real tasks and issues, all on one whiteboard"
        />
        <figcaption className="text-light-blue-800 md:max-w-[400px] z-10 left-0 top-0 pt-8 px-8 text-2xl font-medium tracking-tight leading-tight sm:text-3xl md:absolute">
          View your latest designs while writing real tasks and issues, all on
          one whiteboard
        </figcaption>
      </figure>
      <FigmaSignupBar />
      <div className="my-4 sm:grid sm:gap-6 sm:grid-cols-2 sm:my-8">
        <figure className="bg-rose-100 relative flex flex-col-reverse justify-between my-4 rounded-xl overflow-hidden sm:my-0">
          <div className="ring-opacity-[16%] ring-rose-800 absolute inset-0 rounded-xl ring-2 ring-inset" />
          <img
            className="max-w-[348px] max-h-[348px] self-end w-full"
            src={figmaCopyPaste}
            alt="Simply copy and paste a frame link"
          />
          <figcaption className="text-rose-800 p-8 text-2xl font-medium tracking-tight leading-tight sm:text-3xl">
            Simply copy and paste a frame link
          </figcaption>
        </figure>
        <figure className="bg-amber-100 relative flex flex-col-reverse justify-between my-4 rounded-xl overflow-hidden sm:my-0">
          <div className="ring-opacity-[16%] ring-amber-800 absolute inset-0 rounded-xl ring-2 ring-inset" />
          <img
            className="max-w-[348px] max-h-[348px] self-end w-full"
            src={figmaRefresh}
            alt="Your frames refresh so you always see the latest designs"
          />
          <figcaption className="text-amber-800 p-8 text-2xl font-medium tracking-tight leading-tight sm:text-3xl">
            Your frames refresh so you always see the latest designs
          </figcaption>
        </figure>
      </div>
      <footer className="mt-8">
        <Link
          className="border-border-normal-light active:bg-button-secondary-active-light hover:bg-background-three-light text-text-primary-light block px-8 py-4 text-center text-base font-medium tracking-tight leading-tight border rounded-lg"
          to="/integrations"
        >
          View all integrations
        </Link>
      </footer>
    </div>
  </BasicLayout>
)

export default Figma
